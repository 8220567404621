import React, { useState } from "react";
import { Switch, Button } from "antd";

interface PremiumFeaturesContentProps {
  onUpgradeClick: (subscriptionType: "monthly" | "yearly") => void;
  onClose: () => void;
}

export const PremiumFeaturesContent: React.FC<PremiumFeaturesContentProps> = ({
  onUpgradeClick,
  onClose,
}) => {
  const [isYearly, setIsYearly] = useState(true);
  const monthlyPrice = 15;
  const yearlyPrice = 12;
  const savings = Math.round(
    ((monthlyPrice - yearlyPrice) / monthlyPrice) * 100
  );

  return (
    <>
      {/* Header Section */}
      <div style={styles.header}>
        <h2 style={styles.title}>⭐ Unlock Premium Features</h2>
        <p style={styles.subtitle}>
          Supercharge your workflow with advanced capabilities
        </p>
      </div>

      {/* Pricing Toggle */}
      <div style={styles.toggleWrapper}>
        <span style={isYearly ? styles.toggleLabel : styles.toggleLabelActive}>
          Monthly
        </span>
        <Switch
          checked={isYearly}
          onChange={setIsYearly}
          style={styles.switch}
        />
        <span style={isYearly ? styles.toggleLabelActive : styles.toggleLabel}>
          Yearly
        </span>
      </div>

      {/* Pricing Display */}
      <div style={styles.pricingBox}>
        <div style={styles.priceDisplay}>
          <span style={styles.currencySymbol}>$</span>
          <span style={styles.price}>
            {isYearly ? yearlyPrice : monthlyPrice}
          </span>
          <span style={styles.period}>/month</span>
          {isYearly && <span style={styles.savingsBadge}>Save {savings}%</span>}
        </div>
      </div>

      {/* Benefits Grid */}
      <div style={styles.benefitsGrid}>
        {[
          {
            icon: "⚡",
            title: "Unlimited Access",
            description: "Work with texts of any length",
          },
          {
            icon: "🤖",
            title: "Smarter AI",
            description: "More accurate responses",
          },
          {
            icon: "🎯",
            title: "Customizable",
            description: "Tailor AI responses to your exact needs",
          },
          {
            icon: "📱",
            title: "Apps (coming soon)",
            description: "Access anywhere with native apps",
          },
        ].map((benefit) => (
          <div key={benefit.title} style={styles.benefitCard}>
            <h3 style={styles.benefitTitle}>
              {benefit.icon + " " + benefit.title}
            </h3>
            <p style={styles.benefitDescription}>{benefit.description}</p>
          </div>
        ))}
      </div>

      {/* CTA Section */}
      <div style={styles.ctaSection}>
        <Button
          type="primary"
          block
          size="large"
          onClick={() => onUpgradeClick(isYearly ? "yearly" : "monthly")}
        >
          {isYearly ? "Upgrade Now - Save 20%" : "Upgrade Now"}
        </Button>
        <Button type="link" block onClick={onClose}>
          Maybe later
        </Button>
      </div>

      {/* Social Proof */}
      <div style={styles.socialProof}>
        <span>🔒 Secure payment</span>
        <span style={styles.bullet}>•</span>
        <span>Cancel anytime</span>
        <span style={styles.bullet}>•</span>
        <span>14-day money back</span>
      </div>
    </>
  );
};

const styles = {
  header: {
    marginBottom: "8px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#111827",
    marginBottom: "4px",
  },
  subtitle: {
    color: "#4B5563",
    fontSize: "14px",
  },
  toggleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    marginBottom: "8px",
  },
  toggleLabel: {
    fontSize: "12px",
    color: "#4B5563",
  },
  toggleLabelActive: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#111827",
  },
  switch: {
    backgroundColor: "#D1D5DB",
  },
  pricingBox: {
    backgroundColor: "#F9FAFB",
    borderRadius: "8px",
    padding: "8px",
    marginBottom: "12px",
  },
  priceDisplay: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
    gap: "4px",
  },
  currencySymbol: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#111827",
  },
  price: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#111827",
  },
  period: {
    color: "#4B5563",
  },
  savingsBadge: {
    marginLeft: "4px",
    padding: "2px 6px",
    backgroundColor: "#DEF7EC",
    color: "#057A55",
    fontSize: "10px",
    fontWeight: "500",
    borderRadius: "9999px",
  },
  benefitsGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "6px",
    marginBottom: "12px",
  },
  benefitCard: {
    backgroundColor: "#F9FAFB",
    padding: "6px",
    borderRadius: "8px",
    border: "1px solid #F3F4F6",
    textAlign: "left" as const,
  },
  benefitTitle: {
    fontWeight: "600",
    color: "#111827",
    marginBottom: "2px",
  },
  benefitDescription: {
    fontSize: "12px",
    color: "#4B5563",
  },
  ctaSection: {
    display: "flex",
    flexDirection: "column" as const,
    gap: "6px",
  },
  socialProof: {
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
    fontSize: "12px",
    color: "#6B7280",
  },
  bullet: {
    color: "#D1D5DB",
  },
};
