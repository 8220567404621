import React from "react";
import { Level } from "@tiptap/extension-heading";
import { Editor } from "@tiptap/react";
import { Button, Space, Tooltip } from "antd";
import {
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  AlignLeftOutlined,
  DashOutlined,
  UndoOutlined,
  RedoOutlined,
} from "@ant-design/icons";

interface Props {
  editor: Editor;
}

const MenuBar = ({ editor }: Props) => {
  if (!editor) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Space wrap>
        {/* Undo/Redo group */}
        <div style={{ display: "flex" }}>
          <Tooltip title="Undo">
            <Button
              icon={<UndoOutlined />}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => editor.chain().focus().undo().run()}
            >
              Undo
            </Button>
          </Tooltip>
          <Tooltip title="Redo">
            <Button
              icon={<RedoOutlined />}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => editor.chain().focus().redo().run()}
            >
              Redo
            </Button>
          </Tooltip>
        </div>

        <div style={{ width: 6 }} />

        {/* Text formatting group */}
        {/* <div style={{ display: "flex" }}>
          <Tooltip title="Bold">
            <Button
              icon={<BoldOutlined />}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => editor.chain().focus().toggleBold().run()}
              type={editor.isActive("bold") ? "primary" : "default"}
            />
          </Tooltip>
          <Tooltip title="Italic">
            <Button
              icon={<ItalicOutlined />}
              style={{ borderRadius: 0 }}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              type={editor.isActive("italic") ? "primary" : "default"}
            />
          </Tooltip>
          <Tooltip title="Strike">
            <Button
              icon={<StrikethroughOutlined />}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => editor.chain().focus().toggleStrike().run()}
              type={editor.isActive("strike") ? "primary" : "default"}
            />
          </Tooltip>
        </div>

        <div style={{ width: 30 }} /> */}

        {/* Heading group */}
        {/* <div style={{ display: "flex" }}>
          {[1, 2, 3].map((level, index) => (
            <Tooltip key={level} title={`Heading ${level}`}>
              <Button
                style={{
                  borderRadius: 0,
                  borderTopLeftRadius: index === 0 ? 6 : 0,
                  borderBottomLeftRadius: index === 0 ? 6 : 0,
                  borderTopRightRadius: index === 2 ? 6 : 0,
                  borderBottomRightRadius: index === 2 ? 6 : 0,
                }}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .toggleHeading({ level: level as Level })
                    .run()
                }
                type={
                  editor.isActive("heading", { level }) ? "primary" : "default"
                }
              >
                H{level}
              </Button>
            </Tooltip>
          ))}
        </div>

        <div style={{ width: 30 }} /> */}

        {/* List formatting group */}
        {/* <div style={{ display: "flex" }}>
          <Tooltip title="Bullet list">
            <Button
              icon={<UnorderedListOutlined />}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              type={editor.isActive("bulletList") ? "primary" : "default"}
            />
          </Tooltip>
          <Tooltip title="Ordered list">
            <Button
              icon={<OrderedListOutlined />}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              type={editor.isActive("orderedList") ? "primary" : "default"}
            />
          </Tooltip>
        </div>

        <div style={{ width: 30 }} /> */}
      </Space>
    </div>
  );
};

export default MenuBar;
