import {
  useAuthState,
  useSignInWithGoogle,
  useSignOut,
} from "react-firebase-hooks/auth";
import { auth } from "../libs/firebase";
import { useCallback } from "react";

export function useAuth() {
  const [user, loading, error] = useAuthState(auth);
  const [signInWithGoogle, googleError] = useSignInWithGoogle(auth);
  const [signOut] = useSignOut(auth);

  const login = useCallback(() => {
    return signInWithGoogle();
  }, [signInWithGoogle]);

  const logout = useCallback(() => {
    return signOut();
  }, [signOut]);

  return { user, loading, error: error || googleError, login, logout };
}
