import React, { useState } from "react";
import { Button, Input, Form } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { saveWaitingListEntry } from "../../libs/api";

const { TextArea } = Input;

interface WaitingListContentProps {
  subscriptionType?: "monthly" | "yearly";
}

export const WaitingListContent: React.FC<WaitingListContentProps> = ({
  subscriptionType,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: { email: string; usage: string }) => {
    setIsLoading(true);
    try {
      await saveWaitingListEntry(values.email, values.usage, subscriptionType);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error saving entry:", error);
      // You might want to add error handling UI here
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <div style={styles.successContainer}>
        <CheckCircleFilled style={styles.successIcon} />
        <h2 style={styles.title}>Welcome to the VIP List! 🎉</h2>
        <p style={styles.subtitle}>
          You're all set! We'll reach out personally as soon as premium access
          becomes available. Get ready to unlock amazing features!
        </p>
      </div>
    );
  }

  return (
    <>
      <div style={styles.header}>
        <h2 style={styles.title}>Get Early Premium Access</h2>
        <p style={styles.subtitle}>
          Join our exclusive waitlist and be among the first to experience
          powerful premium features designed for you
        </p>
      </div>

      <Form
        name="waitingList"
        onFinish={onFinish}
        layout="vertical"
        style={styles.form}
      >
        <Form.Item
          name="email"
          validateTrigger={["onBlur"]}
          rules={[
            { required: true, message: "Please add your email address" },
            {
              type: "email",
              message: "Oops! This email address doesn't look quite right",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter your email address"
            style={styles.input}
          />
        </Form.Item>

        <Form.Item
          name="usage"
          rules={[
            {
              required: true,
              message: "Help us understand how you'd like to use our features",
            },
          ]}
        >
          <TextArea
            placeholder="Tell us what you're looking to achieve with our premium features (this helps us prioritize your access)"
            rows={4}
            style={styles.input}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
          >
            Save My Spot
          </Button>
        </Form.Item>
      </Form>

      <div style={styles.socialProof}>
        <span>🔒 100% Secure & Private</span>
        <span style={styles.bullet}>•</span>
        <span>No spam, ever</span>
      </div>
    </>
  );
};

const styles = {
  header: {
    marginBottom: "24px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#111827",
    marginBottom: "4px",
  },
  subtitle: {
    color: "#4B5563",
    fontSize: "14px",
  },
  form: {
    textAlign: "left" as const,
  },
  input: {
    borderRadius: "8px",
  },
  socialProof: {
    marginTop: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
    fontSize: "12px",
    color: "#6B7280",
  },
  bullet: {
    color: "#D1D5DB",
  },
  successContainer: {
    padding: "32px 16px",
    textAlign: "center" as const,
  },
  successIcon: {
    fontSize: "48px",
    color: "#059669",
    marginBottom: "16px",
  },
};
