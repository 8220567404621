const firebaseConfig = {
  apiKey: "AIzaSyDwHfb5zgkZRHb1ifM4xXllx2WDCbLWqNg",
  authDomain: "talktext-ai.firebaseapp.com",
  projectId: "talktext-ai",
  storageBucket: "talktext-ai.appspot.com",
  messagingSenderId: "265371226717",
  appId: "1:265371226717:web:8ff6042586f474473273b4",
  measurementId: "G-G75JYJV2KV",
};

export default firebaseConfig;
