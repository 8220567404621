import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Initialize Firebase
import firebaseConfig from "../config/firebaseConfig";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

// Export Firebase instances
export { db, auth };
