import React, { useState } from "react";
import { Modal } from "antd";
import { PremiumFeaturesContent } from "./PremiumFeaturesContent";
import { WaitingListContent } from "./WaitingListContent";

interface UnlockPremiumModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UnlockPremiumModal: React.FC<UnlockPremiumModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [showAccountForm, setShowAccountForm] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState<
    "monthly" | "yearly" | undefined
  >(undefined);

  const handleUpgradeClick = (subscriptionType: "monthly" | "yearly") => {
    setShowAccountForm(true);
    setSubscriptionType(subscriptionType);
  };

  const handleClose = () => {
    setShowAccountForm(false);
    onClose();
  };

  return (
    <Modal
      title={null}
      open={isOpen}
      onCancel={handleClose}
      footer={null}
      width={560}
      style={{ top: 0 }}
      centered
    >
      <div style={styles.container}>
        {!showAccountForm ? (
          <PremiumFeaturesContent
            onUpgradeClick={handleUpgradeClick}
            onClose={onClose}
          />
        ) : (
          <WaitingListContent subscriptionType={subscriptionType} />
        )}
      </div>
    </Modal>
  );
};

const styles = {
  container: {
    padding: "12px 8px",
    textAlign: "center" as const,
  },
};
