import React from "react";
import { ConfigProvider } from "antd";
import colors from "./colors";

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primary.main,
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeProvider;
