import { Template } from "./types";
import { getFunctions, httpsCallable } from "firebase/functions";

export const transcribeAudio = async (blob: Blob): Promise<string> => {
  try {
    // Convert blob to base64 using FileReader
    const reader = new FileReader();
    const base64Promise = new Promise<string>((resolve) => {
      reader.onloadend = () => {
        // Remove the "data:audio/wav;base64," prefix from the base64 string
        const base64 = (reader.result as string).split(",")[1];
        resolve(base64);
      };
    });
    reader.readAsDataURL(blob);
    const base64 = await base64Promise;

    const functions = getFunctions();
    const callTranscribe = httpsCallable(functions, "transcribeAudio");

    const result = await callTranscribe({
      audioBase64: base64,
    });

    return (result.data as { text: string }).text;
  } catch (error) {
    console.error("Error transcribing audio:", error);
    throw new Error("Failed to transcribe audio");
  }
};

const cleanResponse = (response: string): string => {
  // Remove ```markdown and ``` delimiters
  response = response.replace(/^```markdown\n|```$/g, "");
  // Remove empty lines at the start and end
  response = response.trim();
  return response;
};

export const sendToAssistant = async (
  transcript: string,
  template: Template | undefined,
  content: string
): Promise<string | null> => {
  try {
    const systemMessage = `You are a writing expert. The user is writing the following and they are going to ask you about writing. Be very concise, helpful and respond with markdown. Content: {{${content}}}. The following is the type of text the user is writing: ${template?.description}`;
    const responseMessage = await sendToChatGPT(systemMessage, transcript);

    return responseMessage;
  } catch (error) {
    console.error("Error sending transcript to ChatGPT:", error);
    return null;
  }
};

export const executePrompt = async (
  transcript: string,
  description: string,
  content: string
): Promise<string | null> => {
  try {
    const systemMessage = `You are a writing assistant. Respond only with the updated content in markdown format. The following is the type of text you will be helping to write: ${description}`;
    const userMessage = `Update the following markdown content based on the following command. Provide the fully updated content. Command: {{${transcript}}} Content: {{${content}}}`;
    const responseMessage = await sendToChatGPT(systemMessage, userMessage);

    return cleanResponse(responseMessage || "");
  } catch (error) {
    console.error("Error sending transcript to ChatGPT:", error);
    return null;
  }
};

async function sendToChatGPT(systemMessage: string, userMessage: string) {
  try {
    const functions = getFunctions();
    const callChatGPT = httpsCallable(functions, "sendToChatGPT");

    const result = await callChatGPT({
      systemMessage,
      userMessage,
    });

    return (result.data as { content: string }).content;
  } catch (error) {
    console.error("Error sending transcript to ChatGPT:", error);
    return null;
  }
}

export const processAudioFromDemo = async (
  blob: Blob,
  contentFormat: string,
  styleType: string,
  writingLanguage: string,
  text: string
): Promise<{ content: string; transcript: string }> => {
  try {
    // Convert blob to base64 using FileReader
    const reader = new FileReader();
    const base64Promise = new Promise<string>((resolve) => {
      reader.onloadend = () => {
        // Remove the "data:audio/wav;base64," prefix from the base64 string
        const base64 = (reader.result as string).split(",")[1];
        resolve(base64);
      };
    });
    reader.readAsDataURL(blob);
    const base64 = await base64Promise;

    const functions = getFunctions();
    const callProcessAudioFromDemo = httpsCallable(
      functions,
      "processAudioFromDemo"
    );

    const result = await callProcessAudioFromDemo({
      audioBase64: base64,
      contentFormat,
      styleType,
      writingLanguage,
      text,
    });
    const data = result.data as { content: string; transcript: string };
    data.content = cleanResponse(data.content || "");

    return data;
  } catch (error) {
    console.error("Error processing audio from demo:", error);
    throw new Error("Failed to process audio from demo");
  }
};

export const saveWaitingListEntry = async (
  email: string,
  usage: string,
  subscriptionType?: "monthly" | "yearly"
): Promise<boolean> => {
  try {
    const functions = getFunctions();
    const saveEntry = httpsCallable(functions, "saveWaitingListEntry");
    await saveEntry({ email, usage, subscriptionType });
    return true;
  } catch (error) {
    console.error("Error saving waiting list entry:", error);
    throw new Error("Failed to save waiting list entry");
  }
};
