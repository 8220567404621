const colors = {
  primary: {
    main: "#4CAF50",
    light: "#81C784",
    dark: "#388E3C",
  },
  secondary: {
    main: "#2196F3",
    light: "#64B5F6",
    dark: "#1976D2",
  },
  neutral: {
    white: "#ffffff",
    lightGray: "#f5f5f5",
    gray: "#a0a0a0",
    darkGray: "#4a4a4a",
    black: "#000000",
  },
  accent: {
    orange: "#FF9800",
    purple: "#9C27B0",
  },
  text: {
    primary: "#212121",
    secondary: "#757575",
    disabled: "#BDBDBD",
  },
  background: {
    default: "#ffffff",
    paper: "#f5f5f5",
  },
  error: "#F44336",
  warning: "#FFC107",
  info: "#03A9F4",
  success: "#8BC34A",
};

export default colors;
