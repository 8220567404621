import Icon from "@ant-design/icons";
import { SVGProps } from "react";

export default function StopRecordingIcon(
  props: React.ComponentProps<typeof Icon>
) {
  return (
    <Icon
      component={() => (
        <svg
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
          fill="currentColor"
          {...(props as SVGProps<SVGSVGElement>)}
        >
          <path d="M304 176h416c70.7 0 128 57.3 128 128v416c0 70.7-57.3 128-128 128H304c-70.7 0-128-57.3-128-128V304c0-70.7 57.3-128 128-128z" />
        </svg>
      )}
    />
  );
}
