import React from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

interface HowItWorksModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const HowItWorksModal: React.FC<HowItWorksModalProps> = ({
  isOpen,
  onClose,
}) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (!isOpen && videoRef.current) {
      videoRef.current.pause();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div style={styles.overlay} onClick={handleOverlayClick}>
      <Button
        size="large"
        style={styles.closeButton}
        onClick={onClose}
        icon={<CloseOutlined />}
      />
      <div style={styles.videoContainer} onClick={handleOverlayClick}>
        <div style={styles.header}>
          <p style={styles.subtitle}>
            <b>Welcome to TalkText AI</b>
            <br />
            Speak Naturally in Any Language, Get Polished English Writing
          </p>
        </div>
        <video
          ref={videoRef}
          src="/demo.mp4"
          title="Product Demo"
          controls
          playsInline
          controlsList="nodownload"
          style={styles.video}
        />
      </div>
    </div>
  );
};

const styles: Record<string, React.CSSProperties> = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  closeButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
    fontSize: "32px",
    color: "#fff",
    background: "rgba(0, 0, 0, 0.5)",
    border: "none",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  videoContainer: {
    width: "90%",
    height: "90%",
    maxWidth: "90vw",
    maxHeight: "90vh",
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
    color: "#fff",
    flexShrink: 0,
  },
  subtitle: {
    fontSize: "16px",
    color: "rgba(255, 255, 255, 0.9)",
    maxWidth: "600px",
    margin: "0 auto",
  },
  video: {
    width: "100%",
    height: "calc(100% - 60px)",
    borderRadius: "8px",
    objectFit: "contain",
  },
};

export default HowItWorksModal;
