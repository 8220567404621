import { Select, Typography } from "antd";

interface SelectItem {
  text: string;
  emoji: string;
  description?: string;
}

interface SelectorProps {
  items: SelectItem[];
  selectedIndex: number;
  onSelect: (index: number) => void;
  title: string;
}

export function Selector({
  items,
  selectedIndex,
  onSelect,
  title,
}: SelectorProps) {
  return (
    <div style={styles.container}>
      <Typography.Text strong style={styles.title}>
        {title}
      </Typography.Text>
      <Select
        style={styles.selector}
        popupMatchSelectWidth={false}
        dropdownStyle={styles.dropdown}
        value={selectedIndex}
        onChange={onSelect}
        aria-label="Item selector"
        optionLabelProp="label"
        size="large"
      >
        {items.map((item, index) => (
          <Select.Option
            key={index}
            value={index}
            label={
              <>
                <span role="img" aria-label={`${item.text} icon`}>
                  {item.emoji}
                </span>{" "}
                {item.text}
              </>
            }
          >
            <div style={styles.optionContent}>
              <div style={styles.optionMain}>
                <span role="img" aria-label={`${item.text} icon`}>
                  {item.emoji}
                </span>{" "}
                {item.text}
              </div>
              {item.description && (
                <div style={styles.optionDescription}>{item.description}</div>
              )}
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

interface Styles {
  container: React.CSSProperties;
  selector: React.CSSProperties;
  title: React.CSSProperties;
  optionContent: React.CSSProperties;
  optionMain: React.CSSProperties;
  optionDescription: React.CSSProperties;
  dropdown: React.CSSProperties;
}

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "4px 20px",
    marginBottom: "1em",
  },
  selector: {
    maxWidth: "300px",
    borderRadius: "6px",
  },
  title: {
    fontSize: "0.9em",
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    color: "#666",
  },
  optionContent: {
    padding: "4px 0",
  },
  optionMain: {
    fontWeight: 500,
  },
  optionDescription: {
    fontSize: "0.85em",
    color: "#666",
    marginTop: 4,
  },
  dropdown: {
    minWidth: "250px",
  },
} as const;
